
import { Component, Prop, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import comunas from "@/helpers/comunas.json";
import countries from "@/helpers/countries.json";

interface registerData {
  district: any;
  street: string;
  street_number: string;
  hint: string;
}

@Component
export default class UpdateAddressForm extends Mixins(FormValidator) {
  @Prop({ default: false }) readonly quick_login!: boolean;

  options_district: any = comunas;
  options_street: any = [];
  street_loading = false;
  street_search_empty = false;

  protected formData: registerData = {
    district: "",
    street: "",
    street_number: "",
    hint: ""
  };

  private mounted() {
    window.scrollTo(0, 0);
    this.formData.district = comunas.find(
      district => district.label === this.$store.state.user.adress.district
    );
    this.formData.street = this.$store.state.user.adress.street;
    this.formData.street_number = this.$store.state.user.adress.number;
    this.formData.hint = this.$store.state.user.adress.hint;
  }

  private updateAddress(): void {
    var calle = (this.formData.street as any).nombre
      ? this.formData.street
      : { nombre: this.formData.street };
    this.$axios
      .put(
        "direccions/actualizar",
        {
          id_comuna: Number(this.formData.district.value),
          calle: calle,
          numero: this.formData.street_number,
          aclaratoria: this.formData.hint
        },
        { headers: { Authorization: this.$store.state.user.auth_token } }
      )
      .then(response => {
        this.$emit("success");
        response.data.comuna = comunas.find(
          district => district.value === response.data.id_comuna
        )!.label;
        response.data.region = response.data.nombre_region;
        this.$store.dispatch("updateAddress", response.data);
      })
      .catch(error => {
        this.$emit("error");
      });
  }
  private clearStreet(): void {
    if (this.formData.district.label == this.comuna_cliente) {
      this.formData.street = "";
    }
  }

  private async filterStreet(val: string, update: any, abort: any) {
    this.street_loading = true;
    if (val === "") {
      update(() => {
        this.options_street = [];
      });
      this.street_loading = false;
      this.street_search_empty = true;
      return;
    }
    await this.loadStreets(val);

    setTimeout(() => {
      update(() => {});
    }, 200);

    return;
  }

  private async loadStreets(val: string) {
    const value = val.toLowerCase();
    let streets = await this.apiStreets(val);
    if (streets.err) {
      this.options_street = [];
      return;
    }
    this.street_loading = false;
    this.street_search_empty = false;
    this.options_street = streets;
  }

  private abortFilterFn() {}

  private filterDistrict(val: string, update: any) {
    if (val === "") {
      update(() => {
        this.options_district = comunas;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_district = (comunas as any).filter((comuna: any) => {
        return (
          comuna.label.toLowerCase().indexOf(value) > -1 ||
          comuna.label === this.formData.district.label
        );
      });
    });
  }
}
